<template>
  <div class="contain-wrapper" :class="{'is-more':total<5}">
    <div class="course-wrapper">
      <h4 class="course-keyword">{{ keyword }}&nbsp;学习课程</h4>
      <p class="course-tip">共有{{ total }}个结果</p>
      <div class="course-list-wrapper">
        <course-card class="course-list-detail" v-for="item in courseList" :course="item" :key="item.id"></course-card>
      </div>
      <div class="pagination-wrapper">
        <pagination :total="total"
                    :page.sync="listParams.page"
                    :limit.sync="listParams.limit"
                    @pagination="getCourseList"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getCourseList} from "../../api/course/course";
import courseCard from '@/components/courseCard/index.vue'
import pagination from '@/components/Pagination/index.vue'

export default {
  components: {courseCard, pagination},
  data() {
    return {
      total: 0,
      listParams: {page: 1, limit: 8},
      keyword: '',
      courseList: []
    }
  },
  methods: {
    // 获取课程列表
    getCourseList() {
      getCourseList({
        pageNum: this.listParams.page,
        pageSize: this.listParams.limit,
        courseName: this.keyword
      }).then((res) => {
        this.courseList = res.rows
        this.total = res.total
      })
    },
  },
  mounted() {
    this.keyword = this.$route.query && this.$route.query.keyword
    this.getCourseList()
  },
  watch: {
    '$route'(to) {
      this.keyword = to.query && to.query.keyword
      this.listParams.page = 1
      this.getCourseList()
    }
  }
}
</script>

<style scoped lang="scss">
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contain-wrapper {
  padding: 70px 0;

  &.is-more {
    height: calc(100% - 108px);
  }

  .course-wrapper {
    width: 1400px;
    margin: 0px auto;
    min-height: calc(100%);
    background: #FFFFFF;
    padding: 44px 50px;

    .course-keyword {
      font-weight: 400;
      color: #000000;
      line-height: 60px;
      font-size: 22px;
    }

    .course-tip {
      font-weight: 400;
      color: #AEAEAE;
      font-size: 14px;
    }

    .course-list-wrapper {
      @include center;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: 1366px;
      min-width: 1366px;
      margin: 40px auto 0;

      .course-list-detail {
        margin-bottom: 40px;
        margin-right: 26px;
        cursor: pointer;
        transition: all 0.2s linear;
        box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.1);

        &:hover {
          transform: translateY(-6px);
          box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.1);
        }

        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }
    }

    .pagination-wrapper {
      @include center;
      width: 100%;
    }
  }
}

</style>
